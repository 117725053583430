<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import PageHeader from "@/components/page-header";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";



import axios from 'axios';


import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";

export default {

    page: {
        title: 'aa',
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: this.$route.params.cat_name,
            items: [{
                text: this.$route.params.cat_name,
                href: "/users",
            },
            {
                text: this.$route.params.cat_name,
                active: true,
            },
            ],
            value: ['javascript'],
            cat_name: this.$route.params.cat_name,
            name: '',
            file: "",
            imgsrc: "",
            date: null,
            submitted: false,
            regError: null,
            regSuccess: null,
            tryingToRegister: false,
            isRegisterError: false,
            registerSuccess: false,
            userserror: false,
            userserrormsg: '',
            usersok: false,
            is_admin: false,
            usersokmsg: '',
            userSearchTerm: '',
            currentpage: 0,
            totalpage: 0,
            totalrows: 0,

            updateuser_id: 0,
            usertype: 'user',
            userstatus: '',
            catlist: [],
            passerror: false,
            passok: false,
            passerrormsg: '',
            passokmsg: '',

            status: 'Active',
            isadd: true,
            modaltitle: '',
            buttonname: '',


        };
    },
    validations: {

    },
    computed: {

    },
    components: {
        Layout,

        PageHeader,
        //Multiselect,
        //flatPickr
    }, mounted: function () {
        //alert()
        var a = window.location.href;

        var b = a.split('categories/');
       this.cat_name = b[1].replaceAll('-',' ');
       // alert(c)
        this.getUserData();
    },
    methods: {

        async getUserData() {

            //alert(parseInt(this.currentpage)+ "======" + this.userSearchTerm);
            var result = await axios.post(appConfig.api_url + 'categories/getall', {
                name: '' + this.userSearchTerm,
                cat_name: this.cat_name
            })

            var users = result.data.data.data;

            this.catlist = users;

        },
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        async updateuserprofile() {
            this.submitted = true;
            // stop here if form is invalid

            this.tryingToRegister = true;
            this.regError = null;

            var mode = 'edit';
            if (this.isadd) {
                mode = 'add';
            }

            // alert(appConfig.api_url + '' + goto);

            const result = await axios.post(appConfig.api_url + 'categories/manage', {
                id: this.updateuser_id,
                name: this.name,
                cat_name: this.cat_name,
                mode: mode,
                status: this.status,
            });

            //alert(JSON.stringify(result.data))

            if (result.data.status == 'errors') {
                this.tryingToRegister = false;
                this.regError = result.data.data.data;
                this.isRegisterError = true;
                // return this.authError = result.data.data.data;
            } else {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                this.regSuccess ='Done';
                this.getUserData();
                document.getElementById('closeupdatemodalbutton').click();
                // localStorage.setItem('username', this.user.name)
                // localStorage.setItem('useremail', this.user.useremail)
                // //   alert(result.data.data.data);
                //  console.log(result.data.data.data);
            }


        }, async filteruser() {
            this.catlist = [];
            this.getUserData();
        }, updateuser(id, name, status) {
            this.modaltitle = 'Update ' + this.cat_name;
            this.buttonname = 'Update';
            this.isadd = false;
            this.updateuser_id = id;
            this.name = name;
            this.status = status;
            document.getElementById('openupdatemodalbutton').click();
            this.isRegisterError = false;
            this.registerSuccess = false;

        }, createuser() {
            this.isRegisterError = false;
            this.registerSuccess = false;
            this.modaltitle = 'Create new ' + this.cat_name;
            this.buttonname = 'Add';
            this.isadd = true;
            this.updateuser_id = -1;
            this.name = '';
            this.status = 'Active';
            document.getElementById('openupdatemodalbutton').click();
        }, async UpdateStatus() {

            //alert(this.updateuser_id + "====" + this.userstatus)
            await axios.post(appConfig.api_url + 'categories/manage', {
                id: this.updateuser_id,
                name: this.name,
                status: this.status,
                mode: 'edit'
            })


            //alert(JSON.stringify(result.data));
            //console.log(JSON.stringify(result));
            // this.userslist = users;
            // document.getElementById('openupdatemodalbutton').click();
            this.usersok = true;
            this.usersokmsg = 'User status updated'
            this.getUserData();


        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-3" style="background-color: white">
            <!-- <h3 class="m-2">Users</h3> -->
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="form-icon m-2">
                    <input v-model="userSearchTerm" type="text" @keyup="filteruser"
                        placeholder="Search by name" class="form-control form-control-icon"
                        aria-label="Recipient's username" aria-describedby="button-addon2">
                    <i class=" ri-search-line"></i>
                </div>
                <div class="page-title-right m-2">
                    <button class="btn btn-success " style="float:right" @click="createuser">+ Create
                        {{ cat_name }}</button>
                </div>
            </div>
        </div>

        <form class="needs-validation" @submit.prevent="updateuserprofile" enctype="multipart/form-data">
            <div class="row">
                <div class="card ">


                    <div class="card-body m-2">
                        <div class="col-12">
                            <b-alert v-model="registerSuccess" class="m-3" style="padding-bottom:10px" variant="success"
                                dismissible>
                                {{ regSuccess }}
                            </b-alert>
                        </div>

                        <div class="table-responsive table-card">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">Action</th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user) of catlist" v-bind:key="user.id"
                                        :style="user.status == 'Active' ? 'background-color:#ddfcdd' : 'background-color:#ffe5e5'">
                                        <td><a @click="updateuser(user.id, user.name, user.status)"><i
                                                    class="ri-edit-2-fill"></i></a>
                                        </td>
                                        <td>{{ user.id }}</td>
                                        <td>{{ user.name }}</td>
                                        <td>{{ user.status }}</td>

                                    </tr>

                                </tbody>

                            </table>
                            <!-- end table -->
                        </div>
                        <!--end tab-pane-->


                    </div>
                </div>
                <!--end col-->
            </div>
        </form>
        <div class="row">

        </div>

        <!-- start of create document edit modal -->
        <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
        <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
                        <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                        </b-alert>
                        <form @submit.prevent="updateuserprofile" id="updateDocumentform">

                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <label for="phonenumberInput" class="form-label">Name</label>
                                    <input required type="text" class="form-control"  v-model="name"
                                        placeholder="Enter Name" />
                                </div>
                            </div>
                            <!--end col-->


                            <div class="col-lg-12">
                                <div>
                                    <label for="firstName" class="form-label">Select Status</label>
                                    <select required v-model="status" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="Active">Active</option>
                                        <option value="Disabled">Disabled</option>
                                    </select>
                                    <input type="hidden" v-model="updateuser_id">

                                </div>
                            </div>
                            <!--end col-->

                            <!--end col-->
                            <div class="col-lg-12">
                                <div class="hstack gap-2 justify-content-end">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-primary">{{ buttonname }}</button>
                                </div>
                            </div>
                            <!--end col-->
                        </form>
                    </div>
                    <!--end row-->

                </div>
            </div>
        </div>


        <!--end row-->
    </Layout>
</template>